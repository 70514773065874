export default {
  calc_total(items) {
    let col_name_sum = [
      'clicks',
      'cost',
      'impressions',
      'bounces',
    ]
    let total = {}
    col_name_sum.forEach((col) => {
      let sum = items.reduce((partialSum, vals) => partialSum + vals[col], 0);
      total[col] = Number.isInteger(sum) ? sum : sum.toFixed(2);
    });
    return total;
  }
}
